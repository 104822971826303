<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-4 mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Billing
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="card p-60px" ref="card">
          <div class="row">
            <div class="col-12 col-sm-12 col-xl-5">
              <div class="mt-4 settings-form">
                <div class="input-container">
                  <label for="">Service Type</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="info_billing.plan_name"
                  />
                </div>
                <div class="input-container">
                  <label for="">Activation Date</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="info_billing.activation_date"
                  />
                </div>
                <div class="input-container">
                  <label for="">Next Billing Date</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="info_billing.next_billing_date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: 0,
      info_billing: ""
    };
  },
  async created() {
    const self = this;

    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.calculateHeight;
      }
    } else {
      this.$router.push("/");
    }

    //GET unassigned list
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/billing`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.info_billing = response.data;
      })
      .catch(error => this.makeToast("ERROR", error, "danger"));
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>
